var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { "min-height": "500", "max-height": "600" } },
    [
      _c(
        "v-tabs",
        {
          attrs: {
            grow: "",
            centered: "",
            "show-arrows": "",
            "background-color": "primary",
            dark: "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [_c("v-tab", { attrs: { href: "#tab-1" } }, [_vm._v(" GÖRÜŞMELER")])],
        1
      ),
      _c(
        "v-tabs-items",
        {
          attrs: { touchless: "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { attrs: { value: "tab-1" } },
            [
              _vm.rows.length > 0
                ? _c(
                    "v-list",
                    { attrs: { subheader: "", "two-line": "", dense: "" } },
                    [
                      _vm._l(_vm.rows, function (item) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: item.Id,
                              staticClass: "my-1",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Görüşülen Öğretmen:")]
                                    ),
                                    _vm._v(
                                      " " + _vm._s(item.MeetingPersonnels) + " "
                                    ),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formattedDateTime")(
                                            item.MeetingDate
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  item.MeetingSubjects
                                    ? _c("v-list-item-subtitle", [
                                        _vm._v(
                                          " Görüşme Konusu: " +
                                            _vm._s(item.MeetingSubjects) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  item.CompletedDate == null
                                    ? _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "warning",
                                            label: "",
                                          },
                                        },
                                        [_vm._v("Devam Ediyor ")]
                                      )
                                    : _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "success",
                                            label: "",
                                          },
                                        },
                                        [_vm._v("Tamamlandı")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", {
                            key: item.Id,
                            staticClass: "mx-4",
                          }),
                        ]
                      }),
                    ],
                    2
                  )
                : _c(
                    "v-alert",
                    {
                      staticClass: "mx-2 my-2",
                      attrs: { type: "info", text: "" },
                    },
                    [_vm._v("Görüşme kaydınız bulunmamaktadır. ")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }