<template>
  <v-card min-height="500" max-height="600">
    <v-tabs grow centered show-arrows v-model="tab" background-color="primary" dark>
      <v-tab href="#tab-1"> GÖRÜŞMELER</v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tab">
      <v-tab-item :value="'tab-1'">
        <v-list subheader two-line dense v-if="rows.length > 0">
          <template v-for="item in rows">
            <v-list-item :key="item.Id" @click="goToDetail(item)" class="my-1">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold">Görüşülen Öğretmen:</span>
                  {{ item.MeetingPersonnels }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.MeetingDate | formattedDateTime }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.MeetingSubjects">
                  Görüşme Konusu: {{ item.MeetingSubjects }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip v-if="item.CompletedDate == null" outlined small color="warning" label
                >Devam Ediyor
                </v-chip
                >
                <v-chip v-else outlined small color="success" label>Tamamlandı</v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="item.Id" class="mx-4"></v-divider>
          </template>
        </v-list>
        <v-alert type="info" text class="mx-2 my-2" v-else
        >Görüşme kaydınız bulunmamaktadır.
        </v-alert
        >
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'

export default {
  data() {
    return {
      totalRows: 0,
      rows: [],
      loading: true,
      options: {
        sortBy: ['MeetingDate'],
        sortDesc: [true],
        itemsPerPage: -1,
      },
      filters: {
        StartDate: null,
        EndDate: null
      },
      tab: null
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY hh:mm') : ''
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    //TODO: pagination ekle
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Meeting/student-meeting', {
        sortBy: this.options.sortBy,
        descending: this.options.sortDesc,
        rowsPerPage: this.options.itemsPerPage,
        page: 1,
        Query: this.filters
      })
          .then((data) => {
            this.rows = data.data.Results
            this.totalRows = data.data.TotalNumberOfRecords
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },
    goToDetail(item) {
      this.$router.push({name: 'gorusme', params: {id: item.Id}})
    }
  }
}
</script>
<style scoped>
.v-list {
  height: 600px;
  overflow-y: auto
}
</style>